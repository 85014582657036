export const APP_VERSION = "1.0";

export const API_ROOT = "https://api-staging.whaot.com";
export const API_URL = "https://api-staging.whaot.com";

export const WEBSITE_TITLE = "Whaot";

export const firebaseConfig = {
  apiKey: "AIzaSyDyLt0MsZoCYcBc0-KsAxjN3xdh43p-X4g",
  authDomain: "gigipo-34021.firebaseapp.com",
  databaseURL: "https://gigipo-34021.firebaseio.com",
  projectId: "gigipo-34021",
  storageBucket: "gigipo-34021.appspot.com",
  messagingSenderId: "312289298791",
  appId: "1:312289298791:web:8fe15886c4a117ede407c2",
  measurementId: "G-FGGVEZTJFZ",
};